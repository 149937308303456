import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"

const Katalog: React.FC = () => (
  <Layout>
    <InfoSection title="Katalog">
      <article className="media">
        <figure className="media-left">
          <p className="image">
            <img
              src="/katalog.png"
              style={{
                maxWidth: "256px",
                border: "1px solid rgb(227, 36, 47)",
              }}
            />
          </p>
        </figure>
        <div className="media-content">
          <a
            href="/katalog.pdf"
            className="button is-large is-primary"
            target="_blank"
          >
            <span className="icon is-medium">
              <i className="fas fa-file-pdf"></i>
            </span>
            <span>Pobierz katalog</span>
          </a>
        </div>
      </article>
    </InfoSection>
  </Layout>
)

export default Katalog
